var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.resolutionScreen < 500)?_c('div',{staticClass:"text-center"},[_c('v-snackbar',{attrs:{"timeout":-1,"bottom":""},model:{value:(_vm.shower),callback:function ($$v) {_vm.shower=$$v},expression:"shower"}},[(_vm.countrestoreerror > 0 || _vm.countcancel > 0)?_c('div',{staticClass:"text-right mb-n2 mt-1"},[_c('v-btn',{attrs:{"icon":"","x-small":""},on:{"click":function($event){return _vm.$emit('closedialog')}}},[_c('v-icon',[_vm._v("mdi-close")])],1)],1):_vm._e(),_c('v-card',{staticClass:"mt-3 pa-3",attrs:{"color":"transparent","elevation":"0","width":"320"}},[(_vm.statusrestore === 'start')?_c('p',[_c('span',{staticClass:"pl-2"},[_vm._v(_vm._s(_vm.$t("backupfileandfolder.preparerestore")))]),_c('v-btn',{staticClass:"float-right",staticStyle:{"margin-left":"10px","margin-top":"-4px"},attrs:{"small":"","color":"secondary"},on:{"click":function($event){return _vm.$emit('cancelselectall')}}},[_vm._v(" "+_vm._s(_vm.$t("dragdrop.cancel"))+" ")])],1):(_vm.statusrestore === 'cancelselect')?_c('p',[_c('span',{staticClass:"pl-2"},[_vm._v(_vm._s(_vm.$t("backupfileandfolder.cancelrestore")))])]):(
          _vm.statusrestore === 'restore' &&
            _vm.calpercenrestore === 100 &&
            _vm.countrestoreerror === 0
        )?_c('p',[_vm._v(" "+_vm._s(_vm.$t("backupfileandfolder.restoresuccess"))+" "),_c('span',{staticClass:"float-right"},[_c('v-icon',{attrs:{"color":"green"}},[_vm._v("mdi-check-bold")])],1)]):(_vm.statusrestore === 'restore' && _vm.calpercenrestore >= 0)?_c('p',[(_vm.calpercenrestore !== 100)?_c('span',{staticClass:"pl-2"},[_vm._v(_vm._s(_vm.$t("backupfileandfolder.restoringfilefolder")))]):_vm._e(),(_vm.countrestoreerror > 0)?_c('v-btn',{staticStyle:{"margin-top":"-4px"},attrs:{"small":"","text":""},on:{"click":function($event){return _vm.$emit('detailrestore')}}},[_vm._v(" "+_vm._s(_vm.$t("backupfileandfolder.cannotrestore"))+"  "+_vm._s(_vm.countrestoreerror)+" "+_vm._s(_vm.$t("dragdrop.file"))+" ")]):_vm._e(),_c('span',{staticClass:"float-right"},[_vm._v(_vm._s(_vm.countrestore)+" / "+_vm._s(_vm.filedata.length)+" ("+_vm._s(_vm.calpercenrestore)+"%)")]),(_vm.calpercenrestore !== 100)?_c('v-card-text',{staticClass:"text-right"},[_vm._v(" "+_vm._s(_vm.showdatarestore[_vm.countrestore - 1].file_name)+" "),_c('v-btn',{staticClass:"mr-n5",staticStyle:{"margin-left":"10px","margin-top":"-4px"},attrs:{"small":"","color":"secondary"},on:{"click":function($event){return _vm.$emit('cancelrestore')}}},[_vm._v(" "+_vm._s(_vm.$t("dragdrop.cancel"))+" ")])],1):_vm._e()],1):(
          _vm.statusrestore === 'cancelrestore' && _vm.countcancel > 0
        )?_c('p',[_c('span',{staticClass:"pl-3"},[_vm._v(_vm._s(_vm.$t("backupfileandfolder.cancelrestore")))])]):_vm._e()]),(_vm.statusrestore !== 'start' && _vm.calpercenrestore !== 100)?_c('div',{staticClass:"mr-n2"},[_c('v-progress-linear',{staticClass:"mx-0",attrs:{"value":_vm.calpercenonerestore,"color":"blue-grey","height":"12"}},[_c('strong',[_vm._v(_vm._s(_vm.calpercenonerestore)+"%")])])],1):_vm._e()],1)],1):_c('div',{staticClass:"text-center"},[_c('v-snackbar',{attrs:{"timeout":-1,"bottom":""},model:{value:(_vm.shower),callback:function ($$v) {_vm.shower=$$v},expression:"shower"}},[(_vm.countrestoreerror > 0 || _vm.countcancel > 0)?_c('div',{staticClass:"text-right mb-n2 mt-1"},[_c('v-btn',{attrs:{"icon":"","x-small":""},on:{"click":function($event){return _vm.$emit('closedialog')}}},[_c('v-icon',[_vm._v("mdi-close")])],1)],1):_vm._e(),_c('v-card',{staticClass:"mt-3 pa-3",attrs:{"color":"transparent","elevation":"0","width":"500"}},[(_vm.statusrestore === 'start')?_c('p',[_c('span',{staticClass:"pl-2"},[_vm._v(_vm._s(_vm.$t("backupfileandfolder.preparerestore")))]),_c('v-btn',{staticClass:"float-right",staticStyle:{"margin-left":"10px","margin-top":"-4px"},attrs:{"small":"","color":"secondary"},on:{"click":function($event){return _vm.$emit('cancelselectall')}}},[_vm._v(" "+_vm._s(_vm.$t("dragdrop.cancel"))+" ")])],1):(_vm.statusrestore === 'cancelselect')?_c('p',[_c('span',{staticClass:"pl-2"},[_vm._v(_vm._s(_vm.$t("backupfileandfolder.cancelrestore")))])]):(
          _vm.statusrestore === 'restore' &&
            _vm.calpercenrestore === 100 &&
            _vm.countrestoreerror === 0
        )?_c('p',[_c('span',{staticClass:"pl-2"},[_vm._v(_vm._s(_vm.$t("backupfileandfolder.restoresuccess")))]),_c('span',{staticClass:"float-right"},[_c('v-icon',{attrs:{"color":"green"}},[_vm._v("mdi-check-bold")])],1)]):(
          _vm.statusrestore === 'cancelrestore' && _vm.countcancel > 0
        )?_c('p',[_c('span',{staticClass:"pl-2"},[_vm._v(_vm._s(_vm.$t("backupfileandfolder.cancelrestore")))])]):(_vm.statusrestore === 'restore' && _vm.calpercenrestore >= 0)?_c('p',[(_vm.calpercenrestore !== 100)?_c('span',{staticClass:"pl-2"},[_vm._v(_vm._s(_vm.$t("backupfileandfolder.restoringfilefolder")))]):_vm._e(),(_vm.countrestoreerror > 0)?_c('v-btn',{staticStyle:{"margin-top":"-4px"},attrs:{"small":"","text":""},on:{"click":function($event){return _vm.$emit('detailrestore')}}},[_vm._v(" "+_vm._s(_vm.$t("backupfileandfolder.cannotrestore"))+"  "+_vm._s(_vm.countrestoreerror)+" "+_vm._s(_vm.$t("dragdrop.file"))+" ")]):_vm._e(),_c('span',{staticClass:"float-right"},[_vm._v(_vm._s(_vm.countrestore)+" / "+_vm._s(_vm.filedata.length)+" ("+_vm._s(_vm.calpercenrestore)+"%)")]),(_vm.calpercenrestore !== 100)?_c('v-card-text',{staticClass:"text-right ma-0 pa-0 mb-n2"},[_c('br'),_vm._v(" "+_vm._s(_vm.showdatarestore[_vm.countrestore - 1].file_name)+" "),_c('v-btn',{staticStyle:{"margin-left":"10px","margin-top":"-4px"},attrs:{"small":"","color":"secondary"},on:{"click":function($event){return _vm.$emit('cancelrestore')}}},[_vm._v(" "+_vm._s(_vm.$t("dragdrop.cancel"))+" ")])],1):_vm._e()],1):_vm._e()]),(
        _vm.statusrestore !== 'start' &&
          _vm.calpercenrestore !== 100 &&
          _vm.countcancel === 0
      )?_c('div',{staticClass:"mr-n2"},[_c('v-progress-linear',{staticClass:"mx-0",attrs:{"value":_vm.calpercenonerestore,"color":"blue-grey","height":"12"}},[_c('strong',[_vm._v(_vm._s(_vm.calpercenonerestore)+"%")])])],1):_vm._e()],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }